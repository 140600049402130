import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'

class Table extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         students: [
            { id: 0, name: '',  email: '' , company:'',updatedon:''}
         ]
      }
   }
   renderTableHeader() {
      let header = Object.keys(this.state.students[0])
      return header.map((key, index) => {
         return <th key={index}>{key.toUpperCase()}</th>
      })
   }
   async getUser(){
      var topost = {};
      axios.post('https://connectjob.ca/applicantlist.php', JSON.stringify(topost)).then(response => {
         console.log(response);
         if (response.data.Error && response.data.Error.length > 0) {
             Swal.fire({
                 title: 'Error!',
                 text: 'Could Not Retrieve Data',
                 icon: 'error'
             })
         } else {
            this.setState({
               students:response.data
            },()=>{console.log(this.state)})
      
         }

     })


/*      let usersData=await axios.post('https://connectjob.ca/applicantlist.php')
                      .then(res=>{
                         console.log(res.data)
                        return res.data.data;
                      })
                      .catch(err=>{
                        console.log(err);
                      })
                      console.log('Got',usersData)
      this.setState({
         students:usersData
      },()=>{console.log(this.state)})*/
    }
    componentDidMount(){
      //console.log('Ari Here');
      this.getUser();
    }

   renderTableData() {
      return this.state.students.map((student, index) => {
         const { id, name,  email , company, createdon} = student //destructuring
         return (
            <tr key={id}>
               <td><Link to={{ pathname: `/apdetails/${id}` }}>{id}</Link></td>
               <td>{name}</td>
             
               <td>{email}</td>
               <td>{company}</td>
				<td>{createdon}</td>				   
            </tr>
         )
      })
   }

   render() {
      return (
         <div>
            <h1 id='title'>Applicants Summary</h1>
            <table id='students'>
               <tbody>
                  <tr>{this.renderTableHeader()}</tr>
                  {this.renderTableData()}
               </tbody>
            </table>
         </div>
      )
   }
}

export default Table;