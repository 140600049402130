import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'

var CompanyId='';

class Table extends React.Component {
   constructor(props) {
      super(props)
      console.log(this.props.match.params.id)
      this.state = {
         student: 
            { id: 0, name: '',  f_email: '' , f_positionsapplying:'', f_preferredshifts:'', f_preferreddayofweek:''},
            student1:{},
            student2:{}
         
      }
   }
   
   async getUser(){
      var topost = {
          id:this.props.match.params.id
      };
      axios.post('https://connectjob.ca/appview.php', JSON.stringify(topost)).then(response => {
         console.log('Got Result',response);
         if (response.data.Error && response.data.Error.length > 0) {
             Swal.fire({
                 title: 'Error!',
                 text: 'Could Not Retrieve Data',
                 icon: 'error'
             })
         } else {
             if (response.data.length>0 && response.data[0].f_email){
                this.setState({
                    student:response.data[0]
                 },()=>{console.log(this.state)})
                 if(response.data[1]){
                    this.setState({
                        student1:response.data[1]
                     },()=>{console.log(this.state)})
    
                 }
                 if(response.data[2]){
                    this.setState({
                        student2:response.data[2]
                     },()=>{console.log(this.state)})
                 }
             }
      
         }

     })


/*      let usersData=await axios.post('https://connectjob.ca/applicantlist.php')
                      .then(res=>{
                         console.log(res.data)
                        return res.data.data;
                      })
                      .catch(err=>{
                        console.log(err);
                      })
                      console.log('Got',usersData)
      this.setState({
         students:usersData
      },()=>{console.log(this.state)})*/
    }
    componentDidMount(){
      //console.log('Ari Here');
      this.getUser();
    }


   render() {
      return (
        <div class="row" style={{ margin: 20 }}>
        <div class="col-md-12 ">

            <form class="form-horizontal" role="form">
                <h1 style={{ textAlign: "center" }}>Applicant View</h1>

                <div class="form-group">
                    <div class="col-sm-4">
                        <label for="Email">Email</label>
                        <input  type="email" id="email"  name="email" placeholder="You will use your email address to login" class="form-control"  value={this.state.student.f_email}/>

                      
                            
                    </div>
                    <div class="col-sm-4">
                        <label for="Password">Password</label>
                        <input type="password" id="password" name="password" placeholder="7 characters minimum" class="form-control" />

                    </div>
                    <div class="col-sm-4">
                        <label for="Confirm Password">Confirm Password</label>
                        <input  type="password" name="password" id="confirmpassword" class="form-control" />

                    </div>
                </div>
                <hr />

                <div class="form-group">
                    <div class="col-sm-3">
                        <label for="Salutation">Salutation</label>
                        <input type="text" name="Salutation" id="Salutation" class="form-control" value={this.state.student.f_salutation}/>

                   
</div>
                    <div class="col-sm-3">
                        <label for="ApplicantName">Applicant Name</label>
                        <input   type="text" name="ApplicantName" id="ApplicantName"
                        value={this.state.student.f_applicantname}

                         class="form-control" />
                   
                    </div>
                    <div class="col-sm-3">
                        <label for="DateofBirth">Date Of Birth <span style={{ color: '#31708f' }}>*Must be 18</span></label>
                        <input  type="date" name="DateofBirth" id="DateofBirth" class="form-control" value={this.state.student.f_dob}/>

                  
</div>
                    <div class="col-sm-3">
                        <label for="gender">Gender</label>
                        <select  id="gender" placeholder="Select A Gender" class="form-control" value={this.state.student.f_gender} >
                            <option value="">Select A Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>

                        </select>

                    </div>
                </div>

                <div class="form-group">
                    <div class="col-sm-3">
                        <label for="StreetAddr">Street Address</label>
                        <input type="text" name="StreetAddr" id="StreetAddr" class="form-control" value={this.state.student.f_street}/>

                    </div>
                    <div class="col-sm-3">
                        <label for="city">City</label>
                        <input type="text" name="city" id="city" class="form-control" value={this.state.student.f_city}/>

                    </div>
                    <div class="col-sm-3">
                        <label for="Province">Province</label>
                        <select  id="Province" placeholder="Select A Province" class="form-control" value={this.state.student.f_province}>
                            <option value="">Select A Province</option>
                            <option value="Ontario">Ontario</option>
                            <option value="Quebec">Quebec</option>
                            <option value="Nova_Scotia<">Nova Scotia</option>
                            <option value="New_Brunswick">New Brunswick</option>
                            <option value="Manitoba">Manitoba</option>
                            <option value="British_Columbia">British Columbia</option>
                            <option value="Prince_Edward_Island">Prince Edward Island</option>
                            <option value="Saskatchewan">Saskatchewan</option>
                            <option value="Alberta">Alberta</option>
                            <option value="Newfoundland_and_Labrador">Newfoundland and Labrador</option>
                            <option value="Northwest_Territories">Northwest Territories</option>
                            <option value="Yukon">Yukon</option>
                            <option value="Nunavut">Nunavut</option>

                        </select>

                    </div>
                    <div class="col-sm-3">
                        <label for="PostalCode">Postal Code</label>
                        <input  type="text" name="PostalCode" id="PostalCode" class="form-control" value={this.state.student.f_postalcode}/>

                    </div>
                </div>
                <hr />

                <div class="form-group">
                    <div class="col-sm-3">
                        <label for="PhoneNumber">Phone Number</label>
                        <input type="tel" name="PhoneNumber" id="PhoneNumber" class="form-control" placeholder="(905) 333 4535" value={this.state.student.f_phone}/>
                  
</div>
                    <div class="col-sm-3">
                        <label for="Extension">Extension</label>
                        <input type="text" name="Extension" id="Extension" class="form-control" value={this.state.student.f_extension}/>

                    </div>
                    <div class="col-sm-3">
                        <label for="SIN">SIN(Optional)</label>
                        <input  type="text" name="SIN" id="SIN" class="form-control" value={this.state.student.f_sin}/>
                    </div>
<div class="col-sm-3">
                        <label for="EmergencyContactName">Emergency Contact Name</label>
                        <input type="text" name="EmergencyContactName" id="EmergencyContactName" class="form-control" />
                    </div>

                </div>
                <div class="form-group">
                  
                    <div class="col-sm-3">
                        <label for="EmergencyContactNumber">Emergency Contact Phone Number</label>
                        <input  type="tel" name="EmergencyContactNumber" id="EmergencyContactNumber" class="form-control" placeholder="(905) 333 4535" value={this.state.student.f_emergencyphone}/>


                   
</div>
<div class="col-sm-3">
                        <label for="MajorIntersection1">Major Intersection 1</label>
                        <input type="text" name="MajorIntersection1" id="MajorIntersection1" class="form-control" value={this.state.student.f_majorintersection1}/>

                    </div>
<div class="col-sm-3">
                        <label for="MajorIntersection2">Major Intersection 2</label>
                        <input  type="text" name="MajorIntersection2" id="MajorIntersection2" class="form-control" value={this.state.student.f_majorintersection2}/>

                    </div>
<div class="col-sm-3">
                        <label for="vstatus">Vaccination Status</label>
                        <select id="vstatus" placeholder="Select The Vaccine Status" class="form-control"  value={this.state.student.f_vaccinestatus}>
                            <option value="">Select The Vaccine Status</option>
                            <option value="fullBooster">Fully Vaccinated + Booster</option>
                            <option value="full">Fully Vaccinated</option>
<option value="1dose">1 Dose</option>
<option value="0dose">0 Dose</option>



                        </select>
<h5 style={{ color: '#4c4cca', font: 12 }}> Documentation Should be provided upon employers request.</h5>


                    </div>
                 
                </div>


                <hr />
                <div class="form-group">
                    <div class="col-sm-4">
                        <label for="Positions">Positions Applying For</label><br />
                        <div class="positions" >
                            <input type="checkbox" name="positions[]" value="Administrative Support"  
                                checked={this.state.student.f_positionsapplying.includes('Administrative Support') }
                            /> Administrative Support<br />
                            <input type="checkbox" name="positions[]" value="Clerical"
                                checked={this.state.student.f_positionsapplying.includes('Clerical') }
                                /> Clerical<br />
                            <input type="checkbox" name="positions[]" value="General Office Support"
                                checked={this.state.student.f_positionsapplying.includes('General Office Support') }
                                /> General Office Support<br />
                            <input type="checkbox" name="positions[]" value="Human Resource Professionals" 
                                checked={this.state.student.f_positionsapplying.includes('Human Resource Professionals') }
                                /> Human Resource Professionals<br />
                            <input type="checkbox" name="positions[]" value="Marketing"
                                checked={this.state.student.f_positionsapplying.includes('Marketing') }
                                /> Marketing<br />
                            <input type="checkbox" name="positions[]" value="Reception" 
                                checked={this.state.student.f_positionsapplying.includes('Reception') }
                                /> Reception<br />
                            <input type="checkbox" name="positions[]" value="Call Center" 
                                checked={this.state.student.f_positionsapplying.includes('Call Center') }
                                /> Call Center<br />
                            <input type="checkbox" name="positions[]" value="Telemarketing" 
                                checked={this.state.student.f_positionsapplying.includes('Telemarketing') }
                                 /> Telemarketing
                        <br />
                            <input type="checkbox" name="positions[]" value="Information Technology"  
                                checked={this.state.student.f_positionsapplying.includes('Information Technology') }
                                /> Information Technology<br />
                            <input type="checkbox" name="positions[]" value="Hospitality"  
                                checked={this.state.student.f_positionsapplying.includes('Hospitality') }
                                /> Hospitality<br />
                            <input type="checkbox" name="positions[]" value="Accounting"  
                                checked={this.state.student.f_positionsapplying.includes('Accounting') }
                                /> Accounting<br />
                            <input type="checkbox" name="positions[]" value="Bookkeeping"  
                                checked={this.state.student.f_positionsapplying.includes('Bookkeeping') }
                                /> Bookkeeping<br />
                            <input type="checkbox" name="positions[]" value="Collections"  
                                checked={this.state.student.f_positionsapplying.includes('Collections') }
                                /> Collections<br />
                            <input type="checkbox" name="positions[]" value="Payroll Processing and Support" 
                                checked={this.state.student.f_positionsapplying.includes('Payroll Processing and Support') }
                                 /> Payroll Processing and Support<br />
                            <input type="checkbox" name="positions[]" value="File Clerks"  
                                checked={this.state.student.f_positionsapplying.includes('File Clerks') }
                                /> File Clerks<br />
                            <input type="checkbox" name="positions[]" value="Legal Secretary"  
                                checked={this.state.student.f_positionsapplying.includes('Legal Secretary') }
                                />Legal Secretary<br />
                            <input type="checkbox" name="positions[]" value="Paralegal" 
                                checked={this.state.student.f_positionsapplying.includes('Paralegal') }
                                 />Paralegal<br />
                            <input type="checkbox" name="positions[]" value="RN" 
                                checked={this.state.student.f_positionsapplying.includes('RN') }
                                 /> RN<br />
                            <input type="checkbox" name="positions[]" value="RPN"  
                                checked={this.state.student.f_positionsapplying.includes('RPN') }
                                />RPN<br />
                            <input type="checkbox" name="positions[]" value="PSW" 
                                checked={this.state.student.f_positionsapplying.includes('PSW') }
                                 /> PSW<br />
                            <input type="checkbox" name="positions[]" value="General Labour"  
                                checked={this.state.student.f_positionsapplying.includes('General Labour') }
                                />General Labour<br />
                            <input type="checkbox" name="positions[]" value="Warehouse"  
                                checked={this.state.student.f_positionsapplying.includes('Warehouse') }
                                />Warehouse<br />
                            <input type="checkbox" name="positions[]" value="Loading/Unloading"  
                                checked={this.state.student.f_positionsapplying.includes('Loading/Unloading') }
                                /> Loading/Unloading<br />
                            <input type="checkbox" name="positions[]" value="Packing"
                                 checked={this.state.student.f_positionsapplying.includes('Packing') }
                                 />Packing<br />
                            <input type="checkbox" name="positions[]" value="Shipping/Receiving"  
                                checked={this.state.student.f_positionsapplying.includes('Shipping/Receiving') }
                                /> Shipping/Receiving<br />
                            <input type="checkbox" name="positions[]" value="Inventory"  
                                checked={this.state.student.f_positionsapplying.includes('Inventory') }
                                /> Inventory<br />
                            <input type="checkbox" name="positions[]" value="Assembly line"  
                                checked={this.state.student.f_positionsapplying.includes('Assembly line') }
                                />Assembly line<br />
                            <input type="checkbox" name="positions[]" value="Sanitation"  
                                checked={this.state.student.f_positionsapplying.includes('Sanitation') }
                                />Sanitation<br />
                            <input type="checkbox" name="positions[]" value="Order Picker"  
                                checked={this.state.student.f_positionsapplying.includes('Order Picker') }
                                />Order Picker<br />
                            <input type="checkbox" name="positions[]" value="AZ, DZ, G Driver"  
                                checked={this.state.student.f_positionsapplying.includes('AZ, DZ, G Driver') }
                                />AZ, DZ, G Driver<br />
                            <input type="checkbox" name="positions[]" value="Forklift Operators"  
                                checked={this.state.student.f_positionsapplying.includes('Forklift Operators') }
                                />Forklift Operators<br />
                            <input type="checkbox" name="positions[]" value="Machine Operators"  
                                checked={this.state.student.f_positionsapplying.includes('Machine Operators') }
                                /> Machine Operators<br />
                            <input type="checkbox" name="positions[]" value="CNC Programmers/Operators"  
                                checked={this.state.student.f_positionsapplying.includes('CNC Programmers/Operators') }
                                /> CNC Programmers/Operators<br />
                            <input type="checkbox" name="positions[]" value="Welders"  
                                checked={this.state.student.f_positionsapplying.includes('Welders') }
                                />Welders<br />
                            <input type="checkbox" name="positions[]" value="Quality control"  
                                checked={this.state.student.f_positionsapplying.includes('Quality control') }
                                />Quality control<br />
                            <input type="checkbox" name="positions[]" value="Other"  
                                checked={this.state.student.f_positionsapplying.includes('Other') }
                                />Other<br />
                        </div>
                    </div>
                    <div class="col-sm-8" >
                        <h5 style={{ color: '#4c4cca', font: 14 }}> Expected Earnings (Hourly)</h5>
                        <div class="col-sm-3">
                            <label for="minimum">Minimum</label>
                            <input   type="text" name="minimum" id="minimum" placeholder="ie. $20.00/hour" class="form-control"  value={this.state.student.f_expectedminsalary}/>
                        </div>
                        <div class="col-sm-3">
                            <label for="Expected">Expected</label>
                            <input  type="text" name="Expected" id="Expected" placeholder="ie. $20.00/hour" class="form-control" value={this.state.student.f_expectedmaxsalary}/>
                        </div>
                        <div class="col-sm-6">
                            <label for="Timings">Preferred Timings</label><br />
                            <input type="checkbox" name="timings[]" value="Mornings"  
                                checked={this.state.student.f_preferredshifts.includes('Mornings') }
                            /> Mornings<br />
                            <input type="checkbox" name="timings[]" value="Afternoons"   
                                checked={this.state.student.f_preferredshifts.includes('Afternoons') }
                            /> Afternoons<br />
                            <input type="checkbox" name="timings[]" value="Evenings" 
                                checked={this.state.student.f_preferredshifts.includes('Evenings') }
                             /> Evenings<br />
                            <input type="checkbox" name="timings[]" value="Nights" 
                                checked={this.state.student.f_preferredshifts.includes('Nights') }
                             /> Nights<br />

                        </div>
                        <br />

                        <div class="col-sm-3">

                            <input type="checkbox" name="weekday[]" value="Sunday"  
                                checked={this.state.student.f_preferreddayofweek.includes('Sunday') }
                              /> Sunday<br />
                            <input type="checkbox" name="weekday[]" value="Monday"  
                                checked={this.state.student.f_preferreddayofweek.includes('Monday') }
                             /> Monday<br />
                            <input type="checkbox" name="weekday[]" value="Tuesday"  
                                checked={this.state.student.f_preferreddayofweek.includes('Tuesday') }
                             /> Tuesday<br />
                            <input type="checkbox" name="weekday[]" value="Wednesday"  
                                checked={this.state.student.f_preferreddayofweek.includes('Wednesday') }
                             /> Wednesday<br />


                        </div>
                        <div class="col-sm-3">

                            <input type="checkbox" name="weekday[]" value="Thursday"   
                                checked={this.state.student.f_preferreddayofweek.includes('Thursday') }
                             /> Thursday<br />
                            <input type="checkbox" name="weekday[]" value="Friday"  
                                checked={this.state.student.f_preferreddayofweek.includes('Friday') }
                             /> Friday<br />
                            <input type="checkbox" name="weekday[]" value="Saturday"  
                                checked={this.state.student.f_preferreddayofweek.includes('Saturday') }
                             /> Saturday<br />



                        </div>
                        <div class="col-sm-6">
                            <label for="Transportation">Method Of Transportation</label>
                            <input type="text" name="Transportation" id="Transportation" class="form-control" value={this.state.student.f_transportation}/>
                        </div>
                    </div>

                </div>
                <div class="form-group">
                    <div class="col-sm-4">
<fieldset className="sin" >
                        <label>Question applies to those that do not have a SIN: Do you have a valid work permit, permitting you to legally work in Canada?</label>
                        <input type="radio" name="sin"  id="Yes"   checked={this.state.student.f_permit==1}/> Yes &nbsp;&nbsp;
                    <input type="radio" name="sin"  id="No"  checked={this.state.student.f_permit!=1}
                          /> No<br />
</fieldset>
                        <label style={{ color: '#4c4cca', font: 14 }}>If applying for manufacturing positions (general labour etc.) do you have the following safety equipment:</label>
                        <label>Safety Shoes</label>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;   <input type="radio" name="safety_shoes" checked={this.state.student.f_safetyshoes==1} id="Yes" />  Yes &nbsp;&nbsp;
                    <input type="radio" name="safety_shoes"  id="No" checked={this.state.student.f_safetyshoes!=1}  /> No<br />
                    
                        <label>Safety Glasses</label>&nbsp;&nbsp;&nbsp;    <input type="radio" name="safety_glasses"  checked={this.state.student.f_safetyglasses==1}  id="Yes" />  Yes &nbsp;&nbsp;
                    <input type="radio" name="safety_glasses"  id="No"    checked={this.state.student.f_safetyglasses!=1}  /> No<br />
                    
                        <label>Hard Hat</label> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;<input type="radio" name="equipment" checked={this.state.student.f_hardhat==1}  id="Yes" />  Yes &nbsp;&nbsp;
                    <input  checked={this.state.student.f_hardhat!=1}  type="radio" name="equipment"   id="No"  /> No<br />
                    
                        <label>Safety vest</label>&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <input type="radio" name="safety_vest" checked={this.state.student.f_safetyvest==1} id="Yes" />  Yes &nbsp;&nbsp;
																																	 
																																																										
                    <input type="radio"  checked={this.state.student.f_safetyvest!=1} name="safety_vest"  id="No" /> No<br />

                        <label>Safety gloves</label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="radio" name="safety_gloves" checked={this.state.student.f_safetygloves==1}  id="Yes" />  Yes &nbsp;&nbsp;
                    <input type="radio" name="safety_gloves"   checked={this.state.student.f_safetygloves!=1} id="No"  /> No<br />



                    </div>
                    <div class="col-sm-8">
                        <label for="Education">Education</label>
                        <textarea  type="text" name="Education" id="Education" class="form-control" placeholder="Enter Your Education Details -Max 300 Characters" value={this.state.student.f_education}></textarea>
                    </div>

                </div>
                <div class="bg" >
                    <h4 style={{ color: '#4c4cca' }}>Employment History (List Most Recent Employer First) * Atleast One Employment Record is required</h4>
                    <div class="form-group" >
                        <div class="col-sm-4">
                            <div class="circle">1</div><br />

                            <label for="EmployerName">Employer Name</label>
                            <input type="text" name="EmployerName1" id="EmployerName1" class="form-control" value={this.state.student.f_employername}/><br />
                            <label for="PositionHeld">Position(s) Held</label>
                            <input  type="text" name="PositionHeld1" id="PositionHeld1" class="form-control" value={this.state.student.f_positionshheld}/><br />
                            <label for="Salary">Salary</label>
                            <input     type="text" name="Salary1" id="Salary" class="form-control" value={this.state.student.f_salary}/><br />
                            <label for="Employed From / To">Employed From / To</label>
                            <input type="text" name="Employed_From_To1" id="Employed_From_To1" class="form-control" value={this.state.student.f_joinedon}/><br />
                            <label for="ManagerName">Manager's Name</label>
                            <input  type="text" name="ManagerName1" id="ManagerName1" class="form-control" value={this.state.student.f_manager}/><br />
                            <label for="Contact Number">Contact Number</label>
                            <input  type="text" name="ContactNumber1" id="ContactNumber1" class="form-control" value={this.state.student.f_phone}/><br />
                            <label for="Leaving">Reason For Leaving</label>
                            <textarea  type="text" name="Leaving1" id="Leaving1" class="form-control" placeholder="Max 75 Characters" value={this.state.student.f_reasonforleaving}></textarea><br />
                            <input    type="checkbox" value="" /> May We Contact The Employer?<br />


                        </div>
                        <div class="col-sm-4">
                            <div class="circle">2</div><br />

                            <label for="EmployerName">Employer Name</label>
                            <input type="text" name="EmployerName2" id="EmployerName2" class="form-control" value={this.state.student1.f_employername} /><br />
                            <label for="PositionHeld">Position(s) Held</label>
                            <input  type="text" name="PositionHeld2" id="PositionHeld2" class="form-control" value={this.state.student1.f_positionshheld}/><br />
                            <label for="Salary">Salary</label>
                            <input  type="text" name="Salary2" id="Salary2" class="form-control"  value={this.state.student1.f_salary}/><br />
                            <label for="Employed From / To">Employed From / To</label>
                            <input type="text" name="Employed_From_To2" id="Employed_From_To2" class="form-control"  value={this.state.student1.f_joinedon} /><br />
                            <label for="ManagerName">Manager's Name</label>
                            <input  type="text" name="ManagerName2" id="ManagerName2" class="form-control"  value={this.state.student1.f_manager}/><br />
                            <label for="Contact Number">Contact Number</label>
                            <input  type="text" name="ContactNumber2" id="ContactNumber2" class="form-control"  value={this.state.student1.f_phone}/><br />
                            <label for="Leaving">Reason For Leaving</label>
                            <textarea  type="text" name="Leaving2" id="Leaving2" class="form-control" placeholder="Max 75 Characters" value={this.state.student1.f_reasonforleaving}></textarea><br />
                            <input    type="checkbox" value="" />May We Contact The Employer?<br />


                        </div>
                        <div class="col-sm-4">
                            <div class="circle">3</div><br />

                            <label for="EmployerName">Employer Name</label>
                            <input type="text" name="EmployerName3" id="EmployerName3" class="form-control" value={this.state.student2.f_employername}  /><br />
                            <label for="PositionHeld">Position(s) Held</label>
                            <input  type="text" name="PositionHeld3" id="PositionHeld3" class="form-control" value={this.state.student2.f_positionshheld} /><br />
                            <label for="Salary">Salary</label>
                            <input  type="text" name="Salary3" id="Salary3" class="form-control"  value={this.state.student2.f_salary} /><br />
                            <label for="Employed From / To">Employed From / To</label>
                            <input type="text" name="Employed_From_To3" id="Employed_From_To3" class="form-control"  value={this.state.student2.f_joinedon}/><br />
                            <label for="ManagerName">Manager's Name</label>
                            <input type="text" name="ManagerName3" id="ManagerName3" class="form-control"  value={this.state.student2.f_manager}/><br />
                            <label for="Contact Number">Contact Number</label>
                            <input type="text" name="ContactNumber3" id="ContactNumber3" class="form-control"  value={this.state.student2.f_phone} /><br />
                            <label for="Leaving">Reason For Leaving</label>
                            <textarea  type="text" name="Leaving3" id="Leaving3" class="form-control" placeholder="Max 75 Characters" value={this.state.student2.f_reasonforleaving}></textarea><br />
                            <input  type="checkbox" value="" />May We Contact The Employer?<br />


                        </div>

                    </div>

                </div>
                <div class="form-group">
                

                </div>
              




            </form>
        </div>
    </div>


)
   }
}

export default Table;