import React from 'react';
import axios from 'axios'
import Swal from 'sweetalert2'
import { browserHistory, useNavigate   } from "react-router-dom";


class LoginPage extends React.Component {
    
   SaveApplicant()
   {

       if (this.state.username == "" || this.state.password == "")
       {
           Swal.fire({
               title: 'Error!',
               text: 'Some Fields are empty',
               icon: 'error'
           })

           return false;

       }

       var topost = {
           "applicantid": "tid",
           "uname": this.state.username,
           "upass": this.state.password
      }



      
      
       console.log(JSON.stringify(topost));
       axios.post('https://connectjob.ca/clogin.php', JSON.stringify(topost)).then(response => {
           console.log(response);
           if (response.data.Error && response.data.Error.length > 0) {
               Swal.fire({
                   title: 'Error!',
                   text: 'Could Not Save Data',
                   icon: 'error'
               })
           } else {
               if (response.data.Result && response.data.Result=='Pass' ){
                window.location.href="#/appreport/"
               }
           }

       })

   }

   onChangepassword(Value) {
      this.setState({
          password: Value.target.value
      })
      this.setState({
          pwdstyle: {
              display: Value.target.value.length < 7 ? 'block' : 'none'
          }
      })
  }

  onChangeusername(Value) {
   this.setState({
      username: Value.target.value
   })
}

    render() {
       return (
<div class="row" style={ { margin: 20 } }>
    <div class="col-md-12 ">
        <form class="form-horizontal" role="form">
            <h1 style={ { textAlign: "center" } }> Login </h1>
            <div class="form-group">
                <div class="col-sm-4">
                    <label for="Email"> UserName
                    </label> <input onChange={ this.onChangeusername.bind(this) } type="name" id="username"
                        name="username" placeholder="Enter your name" class="form-control" />
                </div>
                <div class="col-sm-4">
                    <label for="Password"> Password
                    </label> <input onChange={ this.onChangepassword.bind(this) } type="password" id="password"
                        name="password" placeholder="Enter Your password" class="form-control" />
                </div>
                </div>
                <div class="form-group">
                    <div class="col-sm-12">
                        <button type='button' onClick={ this.SaveApplicant.bind(this) } class="btn btn-sub"> Submit
                        </button> </div> <br />
                </div>
        </form>
    </div>
</div>
       )
    }
 }
 export default LoginPage;
